import React, { useEffect, useRef } from "react";
import { mat4 } from "gl-matrix";
import { motion } from "framer-motion";
import { pageVariants } from "./animationVariants";
// import "./Home.css";

const Home: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) return;

    const gl = canvas.getContext("webgl");
    if (!gl) {
      console.error("WebGL not supported");
      document.body.innerHTML = "WebGL is not supported in your browser.";
      return;
    }

    const vsSource = `
      attribute vec4 aVertexPosition;
      attribute vec4 aVertexColor;
      uniform mat4 uModelViewMatrix;
      uniform mat4 uProjectionMatrix;
      varying vec4 vColor;
      void main(void) {
          gl_Position = uProjectionMatrix * uModelViewMatrix * aVertexPosition;
          vColor = aVertexColor;
      }
    `;

    const fsSource = `
      precision mediump float;
      varying vec4 vColor;
      uniform float uOpacity;
      uniform vec4 uBackgroundColor;
      void main(void) {
          gl_FragColor = mix(uBackgroundColor, vColor, uOpacity);
      }
    `;

    const wireframeVsSource = `
      attribute vec4 aVertexPosition;
      uniform mat4 uModelViewMatrix;
      uniform mat4 uProjectionMatrix;
      void main(void) {
          gl_Position = uProjectionMatrix * uModelViewMatrix * aVertexPosition;
      }
    `;

    const wireframeFsSource = `
      precision mediump float;
      uniform float uOpacity;
      void main(void) {
          gl_FragColor = vec4(0.0, 0.0, 0.0, uOpacity);
      }
    `;

    function createShaderProgram(
      gl: WebGLRenderingContext,
      vsSource: string,
      fsSource: string
    ) {
      const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
      const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);
      if (!vertexShader || !fragmentShader) return null;

      const shaderProgram = gl.createProgram();
      if (!shaderProgram) return null;

      gl.attachShader(shaderProgram, vertexShader);
      gl.attachShader(shaderProgram, fragmentShader);
      gl.linkProgram(shaderProgram);

      if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        console.error(
          "Unable to initialize the shader program: " +
            gl.getProgramInfoLog(shaderProgram)
        );
        return null;
      }
      return shaderProgram;
    }

    function loadShader(
      gl: WebGLRenderingContext,
      type: number,
      source: string
    ) {
      const shader = gl.createShader(type);
      if (!shader) return null;

      gl.shaderSource(shader, source);
      gl.compileShader(shader);

      if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error(
          "An error occurred compiling the shaders: " +
            gl.getShaderInfoLog(shader)
        );
        gl.deleteShader(shader);
        return null;
      }
      return shader;
    }

    const shaderProgram = createShaderProgram(gl, vsSource, fsSource);
    const wireframeShaderProgram = createShaderProgram(
      gl,
      wireframeVsSource,
      wireframeFsSource
    );

    if (!shaderProgram || !wireframeShaderProgram) return;

    const programInfo = {
      program: shaderProgram,
      attribLocations: {
        vertexPosition: gl.getAttribLocation(shaderProgram, "aVertexPosition"),
        vertexColor: gl.getAttribLocation(shaderProgram, "aVertexColor"),
      },
      uniformLocations: {
        projectionMatrix: gl.getUniformLocation(
          shaderProgram,
          "uProjectionMatrix"
        ),
        modelViewMatrix: gl.getUniformLocation(
          shaderProgram,
          "uModelViewMatrix"
        ),
        opacity: gl.getUniformLocation(shaderProgram, "uOpacity"),
        backgroundColor: gl.getUniformLocation(
          shaderProgram,
          "uBackgroundColor"
        ),
      },
    };

    const wireframeProgramInfo = {
      program: wireframeShaderProgram,
      attribLocations: {
        vertexPosition: gl.getAttribLocation(
          wireframeShaderProgram,
          "aVertexPosition"
        ),
      },
      uniformLocations: {
        projectionMatrix: gl.getUniformLocation(
          wireframeShaderProgram,
          "uProjectionMatrix"
        ),
        modelViewMatrix: gl.getUniformLocation(
          wireframeShaderProgram,
          "uModelViewMatrix"
        ),
        opacity: gl.getUniformLocation(wireframeShaderProgram, "uOpacity"),
      },
    };

    function initBuffers(gl: WebGLRenderingContext) {
      const radius = 1.2;
      const height = 1.6;
      const segments = 6;
      const colors: [number, number, number, number][] = [
        [0.93, 0.11, 0.14, 1.0],
        [0.17, 0.17, 0.17, 1.0],
        [0.58, 0.84, 0.86, 1.0],
        [0.93, 0.11, 0.14, 1.0],
        [0.17, 0.17, 0.17, 1.0],
        [0.58, 0.84, 0.86, 1.0],
      ];

      let positions: number[] = [];
      let colorData: number[] = [];
      let indices: number[] = [];
      let wireframeIndices: number[] = [];

      for (let i = 0; i < segments; i++) {
        const angle1 = (i / segments) * Math.PI * 2;
        const angle2 = ((i + 1) / segments) * Math.PI * 2;

        const x1 = radius * Math.cos(angle1);
        const z1 = radius * Math.sin(angle1);
        const x2 = radius * Math.cos(angle2);
        const z2 = radius * Math.sin(angle2);

        positions = positions.concat([x1, 0, z1, x2, 0, z2, 0, height, 0]);

        const color = colors[i];
        for (let j = 0; j < 3; j++) {
          colorData = colorData.concat(color);
        }

        const baseIndex = i * 3;
        indices = indices.concat([baseIndex, baseIndex + 1, baseIndex + 2]);

        // Add edge from base to apex
        wireframeIndices = wireframeIndices.concat([baseIndex, baseIndex + 2]);

        // Add base line for every other segment
        if (i % 2 === 0) {
          wireframeIndices = wireframeIndices.concat([
            baseIndex,
            baseIndex + 1,
          ]);
        }
      }

      const positionBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array(positions),
        gl.STATIC_DRAW
      );

      const colorBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
      gl.bufferData(
        gl.ARRAY_BUFFER,
        new Float32Array(colorData),
        gl.STATIC_DRAW
      );

      const indexBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
      gl.bufferData(
        gl.ELEMENT_ARRAY_BUFFER,
        new Uint16Array(indices),
        gl.STATIC_DRAW
      );

      const wireframeIndexBuffer = gl.createBuffer();
      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, wireframeIndexBuffer);
      gl.bufferData(
        gl.ELEMENT_ARRAY_BUFFER,
        new Uint16Array(wireframeIndices),
        gl.STATIC_DRAW
      );

      return {
        position: positionBuffer,
        color: colorBuffer,
        indices: indexBuffer,
        vertexCount: indices.length,
        wireframe: {
          position: positionBuffer,
          indices: wireframeIndexBuffer,
          vertexCount: wireframeIndices.length,
        },
      };
    }

    const buffers = initBuffers(gl);

    function drawScene(
      gl: WebGLRenderingContext,
      programInfo: any,
      wireframeProgramInfo: any,
      buffers: any,
      rotation: number,
      opacity: number
    ) {
      const backgroundColor: [number, number, number, number] = [
        0.94, 0.94, 0.94, 1.0,
      ]; // #f0f0f0
      gl.clearColor(...backgroundColor);
      gl.clearDepth(1.0);
      gl.enable(gl.DEPTH_TEST);
      gl.depthFunc(gl.LEQUAL);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

      const fieldOfView = (75 * Math.PI) / 180;
      const aspect =
        (canvas as HTMLCanvasElement).clientWidth /
        (canvas as HTMLCanvasElement).clientHeight;
      const zNear = 0.1;
      const zFar = 100.0;
      const projectionMatrix = mat4.create();
      mat4.perspective(projectionMatrix, fieldOfView, aspect, zNear, zFar);

      const modelViewMatrix = mat4.create();
      mat4.translate(modelViewMatrix, modelViewMatrix, [0.0, -0.5, -3.5]);
      mat4.rotate(modelViewMatrix, modelViewMatrix, Math.PI / 6, [1, 0, 0]);
      mat4.rotate(modelViewMatrix, modelViewMatrix, rotation, [0, 1, 0]);

      // Draw solid pyramid
      gl.useProgram(programInfo.program);
      gl.uniformMatrix4fv(
        programInfo.uniformLocations.projectionMatrix,
        false,
        projectionMatrix
      );
      gl.uniformMatrix4fv(
        programInfo.uniformLocations.modelViewMatrix,
        false,
        modelViewMatrix
      );

      gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
      gl.vertexAttribPointer(
        programInfo.attribLocations.vertexPosition,
        3,
        gl.FLOAT,
        false,
        0,
        0
      );
      gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);

      gl.bindBuffer(gl.ARRAY_BUFFER, buffers.color);
      gl.vertexAttribPointer(
        programInfo.attribLocations.vertexColor,
        4,
        gl.FLOAT,
        false,
        0,
        0
      );
      gl.enableVertexAttribArray(programInfo.attribLocations.vertexColor);

      gl.uniform1f(programInfo.uniformLocations.opacity, opacity);
      gl.uniform4fv(
        programInfo.uniformLocations.backgroundColor,
        backgroundColor
      );

      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indices);
      gl.drawElements(gl.TRIANGLES, buffers.vertexCount, gl.UNSIGNED_SHORT, 0);

      // Draw wireframe
      gl.useProgram(wireframeProgramInfo.program);
      gl.uniformMatrix4fv(
        wireframeProgramInfo.uniformLocations.projectionMatrix,
        false,
        projectionMatrix
      );
      gl.uniformMatrix4fv(
        wireframeProgramInfo.uniformLocations.modelViewMatrix,
        false,
        modelViewMatrix
      );

      gl.bindBuffer(gl.ARRAY_BUFFER, buffers.wireframe.position);
      gl.vertexAttribPointer(
        wireframeProgramInfo.attribLocations.vertexPosition,
        3,
        gl.FLOAT,
        false,
        0,
        0
      );
      gl.enableVertexAttribArray(
        wireframeProgramInfo.attribLocations.vertexPosition
      );

      gl.uniform1f(
        wireframeProgramInfo.uniformLocations.opacity,
        1.0 - opacity
      );

      gl.disable(gl.DEPTH_TEST);
      gl.enable(gl.BLEND);
      gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.wireframe.indices);
      gl.drawElements(
        gl.LINES,
        buffers.wireframe.vertexCount,
        gl.UNSIGNED_SHORT,
        0
      );
      gl.disable(gl.BLEND);
      gl.enable(gl.DEPTH_TEST);
    }

    let rotation = 0;
    let rotationSpeed = 0.015;
    let targetRotationSpeed = 0.015;
    let currentOpacity = 0;
    let targetOpacity = 0;

    const GRAY_FACE_ANGLES = [
      0, // 0 degrees
      Math.PI, // 180 degrees
    ];

    function getNearestGrayFaceAngle(currentRotation: number) {
      currentRotation = currentRotation % (2 * Math.PI);
      if (currentRotation < 0) currentRotation += 2 * Math.PI;

      return GRAY_FACE_ANGLES.reduce((nearest, angle) => {
        const diff = Math.abs(angle - currentRotation);
        const nearestDiff = Math.abs(nearest - currentRotation);
        return diff < nearestDiff ? angle : nearest;
      });
    }

    function isPointInTriangle(
      px: number,
      py: number,
      ax: number,
      ay: number,
      bx: number,
      by: number,
      cx: number,
      cy: number
    ) {
      const v0x = cx - ax;
      const v0y = cy - ay;
      const v1x = bx - ax;
      const v1y = by - ay;
      const v2x = px - ax;
      const v2y = py - ay;

      const dot00 = v0x * v0x + v0y * v0y;
      const dot01 = v0x * v1x + v0y * v1y;
      const dot02 = v0x * v2x + v0y * v2y;
      const dot11 = v1x * v1x + v1y * v1y;
      const dot12 = v1x * v2x + v1y * v2y;

      const invDenom = 1 / (dot00 * dot11 - dot01 * dot01);
      const u = (dot11 * dot02 - dot01 * dot12) * invDenom;
      const v = (dot00 * dot12 - dot01 * dot02) * invDenom;

      return u >= 0 && v >= 0 && u + v < 1;
    }

    function onMouseMove(event: MouseEvent) {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const rect = canvas.getBoundingClientRect();
      const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      const isOverPyramid = isPointInTriangle(
        x,
        y,
        -0.5,
        -0.5,
        0.5,
        -0.5,
        0,
        0.5
      );

      if (isOverPyramid) {
        targetRotationSpeed = 0;
        targetOpacity = 1;
      } else {
        targetRotationSpeed = 0.015;
        targetOpacity = 0;
      }
    }

    canvas.addEventListener("mousemove", onMouseMove);

    function render() {
      if (!gl) return;

      if (targetRotationSpeed === 0) {
        const targetRotation = getNearestGrayFaceAngle(rotation);
        rotation += (targetRotation - rotation) * 0.1;
      } else {
        rotationSpeed += (targetRotationSpeed - rotationSpeed) * 0.02;
        rotation += rotationSpeed;

        rotation %= 2 * Math.PI;
      }

      currentOpacity += (targetOpacity - currentOpacity) * 0.02;

      drawScene(
        gl,
        programInfo,
        wireframeProgramInfo,
        buffers,
        rotation,
        currentOpacity
      );

      requestAnimationFrame(render);
    }

    function resizeCanvas() {
      if (!gl) return;

      if (canvas instanceof HTMLCanvasElement) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        gl.viewport(0, 0, canvas.width, canvas.height);
      }
    }

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    // Start the rendering loop
    requestAnimationFrame(render);
  }, []);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      style={{ width: "100%" }}
    >
      <div className="content">
        <canvas id="glCanvas" ref={canvasRef}></canvas>
        <div className="home-text">ADPTVE</div>
        <div className="home-text-sm">web innovation</div>
      </div>
    </motion.div>
  );
};

export default Home;
