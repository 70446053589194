import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/menu.css';

interface MenuProps {
  isOpen: boolean;
}

const Menu: React.FC<MenuProps> = ({ isOpen }) => {
  const [fadeIn, setFadeIn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Trigger fade-in after the component has mounted
    setTimeout(() => setFadeIn(true), 0);
    
    return () => setFadeIn(false); // Reset for fade-out on unmount or navigation
  }, [location.pathname]);
  

  // Determine current page based on pathname
  const getCurrentPage = () => {
    switch (location.pathname) {
      case '/services':
        return 'services';
      case '/articles':
        return 'articles';
      case '/about':
        return 'about';
      case '/contact':
        return 'contact';
      default:
        return 'home';
    }
  };

  const currentPage = getCurrentPage();

  const sections = {
    services: 5,
    articles: 3,
    about: 0,
    contact: 0,
    home: 0
  };

  const sectionCount = currentPage === 'home' ? 0 : sections[currentPage];
  const sectionButtons = [];

  for (let i = 1; i <= sectionCount; i++) {
    sectionButtons.push(
      <div key={i} className={`section-button ${fadeIn ? 'fade-in' : 'fade-out'}`} onClick={() => scrollToSection(i)}>
        <svg viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="6" stroke="#838383" strokeWidth="1" fill="#838383" />
          <text x="12" y="14" textAnchor="middle" fill="white" fontSize="6">{i}</text>
        </svg>
      </div>
    );

    if (i < sectionCount) {
      sectionButtons.push(<div key={`sep-${i}`} className="section-separator"></div>);
    }
  }

  const scrollToSection = (sectionId: number) => {
    const sectionElement = document.getElementById(`sectionId-${sectionId}`);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="menu" style={{ width: isOpen ? '8vw' : '0' }}>
      <div className="menu-inner">
        <Link to="/" className="menu-item" data-tilt>
          <svg viewBox="0 0 24 24">
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
          </svg>
        </Link>
        <Link to="/services" className="menu-item" data-tilt>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.07.62-.07.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22-.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z" />
          </svg>
        </Link>
        {/* <Link to="/articles" className="menu-item" data-tilt>
          <svg viewBox="0 0 24 24">
            <path d="M5 2C4.45 2 4 2.45 4 3V21C4 21.55 4.45 22 5 22H19C19.55 22 20 21.55 20 21V3C20 2.45 19.55 2 19 2H5ZM6 4H18V20H6V4ZM8 7V9H16V7H8ZM8 11V13H14V11H8ZM8 15V17H12V15H8Z" />
          </svg>
        </Link>
        <Link to="/about" className="menu-item" data-tilt>
          <svg viewBox="0 0 24 24">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
          </svg>
        </Link> */}
        <Link to="/contact" className="menu-item" data-tilt>
          <svg viewBox="0 0 24 24">
            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
          </svg>
        </Link>

        {/* Section Menu */}
        {sectionCount > 0 && (
          <div className="section-menu">
            {sectionButtons}
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
