import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { pageVariants } from "./animationVariants";

import pageData from "../data/services.json"; // Import the JSON file
import "../styles/services.css"; // Service-specific styles
import "../styles/page.css"; // Global page styles

interface Service {
  subtitle: string;
  description: string;
}

interface Section {
  id: number;
  title: string;
  bannerImage: string;
  sectionType?: string; // Added sectionType
  services: Service[];
}

interface PageData {
  headerImage: string;
  sections: Section[];
}

const Services = () => {
  const page = pageData[0];
  const pageSectionsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          } else {
            entry.target.classList.remove("in-view");
          }
        });
      },
      { threshold: 0.8 } // Trigger when 80% of the section is in view
    );

    pageSectionsRef.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      if (pageSectionsRef.current) {
        pageSectionsRef.current.forEach((section) => {
          if (section) {
            observer.unobserve(section);
          }
        });
      }
    };
  }, []);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      style={{ width: "100%" }}
    >
      <div 
        className="page-hero" // Changed from service-hero
        style={{ backgroundImage: `url(${page.headerImage})` }}  
      >
        <div className='title-text'>SERVICES</div>
        <div className="subtitle"></div>
      </div>
      <div className="page-content"> {/* Changed from services-page */}
        {page.sections.map((section, index) => (
          <div
            id={`sectionId-${section.id}`}
            key={section.id}
            className={`page-section ${
              section.sectionType === "full" ? "full-section" : 
              index % 2 === 0 ? "image-left" : "image-right"
            }`}
            ref={(el) => (pageSectionsRef.current[index] = el)}
          >
            <div
              className="section-image"
              style={{ backgroundImage: `url(${section.bannerImage})` }}
            >
              <div className="text-overlay">
                <h2 className="section-title-overlay">{section.title}</h2>
              </div>
            </div>
            <div className="section-content">
              <div className="section-details">
                {section.services.map((service, idx) => (
                  <div key={idx} className="section-detail">
                    <h3>{service.subtitle}</h3>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default Services;
