import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { pageVariants } from "./animationVariants";

import pageData from "../data/contact.json"; // Import the JSON file
import "../styles/contact.css"; // Assuming CSS file remains the same for now

interface Service {
  subtitle: string;
  description: string;
}

interface Section {
  id: number;
  title: string;
  bannerImage: string;
  sectionType?: string; // Added sectionType
  services: Service[];
}

interface PageData {
  headerImage: string;
  sections: Section[];
}

const Contact = () => {
  const page = pageData[0];
  const pageSectionsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          } else {
            entry.target.classList.remove("in-view");
          }
        });
      },
      { threshold: 0.8 } // Trigger when 80% of the section is in view
    );

    pageSectionsRef.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      if (pageSectionsRef.current) {
        pageSectionsRef.current.forEach((section) => {
          if (section) {
            observer.unobserve(section);
          }
        });
      }
    };
  }, []);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      style={{ width: "100%" }}
    >
      <div className="contact-page">
        {/* Hero Section */}
        <div
          className="hero-section"
          style={{ backgroundImage: "url(/images/header-2.png)" }}
        >
          <div className="title-text">CONTACT</div>
        </div>

        {/* Contact Form Section */}
        <div className="contact-form-section">
          <h2>Let's Collaborate</h2>
          <p>
            Fill out the form below to start a conversation. Whether you have a
            project in mind or just want to ask a question, we're ready to
            assist.
          </p>
          <form
            className="contact-form"
            action="https://formspree.io/f/mkgwbvoy"
            method="POST"
          >
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows={6}
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-button">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default Contact;
