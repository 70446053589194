import React, { ReactNode, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (typeof window !== 'undefined' && 'VanillaTilt' in window) {
      const VanillaTilt = (window as any).VanillaTilt;
      if (VanillaTilt) {
        VanillaTilt.init(document.querySelectorAll(".menu-item"), {
          max: 25,
          speed: 400,
          glare: true,
          "max-glare": 0.5,
        });
      }
    }
  }, [location]);

  return (
    <>
    <div className="container">
      <div className="content-area" style={{ marginRight: menuOpen ? '10vw' : '0', marginLeft: menuOpen ? '0' : '0' }}>
        <div className="hamburger-container">
          <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={() => setMenuOpen(!menuOpen)}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className="page-container">
          {children}
        </div>
      </div>
      <Footer />
    </div>
    <Menu isOpen={menuOpen} />
    </>
  );
};

export default Layout;
