import { Variants } from 'framer-motion';

export const pageVariants: Variants = {
  initial: {
    x: '100%',
    opacity: 1,
  },
  in: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 150, // Increased stiffness for faster transition
      damping: 12,    // Lower damping for more bounce
      mass: 0.75,     // Slightly heavier mass for noticeable bounce
    },
  },
  out: {
    x: '-100%',
    opacity: 1,
    transition: {
        type: 'tween',  // Simple slide out, no spring needed
        ease: 'easeInOut',
        duration: 0.3,  // Quick exit to minimize any pause
    },
  },
};
